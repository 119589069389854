import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .top-search-window {
    background: ${COLORS.purple900};
    padding: 8px 16px;
    isolation: isolate;
    position: relative;
    z-index: 2;
    .title {
      margin: 0 auto 12px;
      width: fit-content;
    }
    :global(.auto-suggest-container) {
      background: ${COLORS.purple900};
      margin: 0 auto;
      :global(.auto-suggest-form) {
        margin: auto;
      }
    }
  }
`;

export default styles;
